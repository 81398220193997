import Vue from "vue"
import {localStorage} from '@/common/helpers'

import {
    ACCOUNT,
    ACCOUNT_ACTIVE,
    SET_ACCOUNT,
    BALANCE_UPDATE,
    USER,
    NOTIFICATION_ORDER,
    CFD_ORDERS,
    CFD_HISTORY,
    CFD_ADD_PROFIT_TOTAL,
    CFD_ORDER,
    SYMBOL,
    NOTIFICATION_ORDER_OPEN,
    NOTIFICATION,
    CFD_TRADE_DISABLED,
    DATA_REFRESH,
    SYMBOL_REMOVE,
    NOTIFICATION_ERROR,
    SORT_ORDERS, SORT_HISTORY_ORDERS,
    SET_LIGHT_THEME
} from '@/store/mutations.type'
import RestService from "@/common/rest.service";
import {
    CFD_ORDER_CLOSE_REQUEST, CFD_ORDER_MODIFY_REQUEST,
    CFD_ORDER_OPEN_REQUEST, DEMO_ACCOUNT_RESET,
    LOGOUT, PLAY_ORDER_LOSE, PLAY_ORDER_OPEN, PLAY_ORDER_WIN
} from '@/store/actions.type'
import {currencySign} from "@/common/helpers";

const state = {
    accounts: {},
    activeAccount: 0,
    demoAccount: 0,
    realAccount: 0,
    tradeDisabled: false,
    currentSort: 'ASC',
    sortBy: 'id',
    lightTheme: localStorage.getItem('theme') == 'white' ? true : false
}

const getters = {
    accounts() {
        return state.accounts
    },

    activeAccount() {
        return state.activeAccount && state.accounts[state.activeAccount] !== undefined ? state.accounts[state.activeAccount] : {
            id: 0,
            currency: 'USD',
            group: 'main',
            balance: 0,
            credit: 0,
            precision: 2,
            orders: {},
            history: [],
            profitTotal: 0,
            demo: true
        }
    },

    realAccount() {
        return state.realAccount && state.accounts[state.realAccount] !== undefined ? state.accounts[state.realAccount] : {
            id: 0,
            currency: 'USD',
            group: 'main',
            balance: 0,
            precision: 2,
            orders: {},
            history: [],
            demo: false
        }
    },

    demoAccount() {
        return state.demoAccount && state.accounts[state.demoAccount] !== undefined ? state.accounts[state.demoAccount] : {
            id: 0,
            currency: 'USD',
            group: 'main',
            balance: 0,
            precision: 2,
            orders: {},
            history: [],
            demo: true
        }
    },

    orders() {
        return state.accounts[state.activeAccount] ? state.accounts[state.activeAccount].orders : {}
    },

    history() {
        return state.accounts[state.activeAccount] ? state.accounts[state.activeAccount].history : []
    },

    tradeDisabled() {
        return state.tradeDisabled
    },

    lightTheme(){
        return state.lightTheme
    }
}

const actions = {
    [CFD_ORDER_OPEN_REQUEST](context, order) {
        if (state.tradeDisabled) {
            return
        }

        this.commit(CFD_TRADE_DISABLED, true)
        RestService.post('/cfd/'+order.account_id+'/orders', order, () => {
            this.commit(CFD_TRADE_DISABLED, false)
        })
            .then(() => {
                this.commit(CFD_TRADE_DISABLED, false)
            })
    },

    [CFD_ORDER_CLOSE_REQUEST](context, order) {
        if (state.tradeDisabled) {
            return
        }

        this.commit(CFD_TRADE_DISABLED, true)
        RestService.delete('/cfd/'+order.account_id+'/order/'+order.id, () => {
            this.commit(CFD_TRADE_DISABLED, false)
        })
            .then(() => {
                this.commit(CFD_TRADE_DISABLED, false)
                delete state.accounts[state.activeAccount].orders[order.index]
            })
    },

    [CFD_ORDER_MODIFY_REQUEST](context, order) {
        RestService.put('/cfd/'+order.account_id+'/order/'+order.id, order)
            .then(() => {

            })
    },

    [DEMO_ACCOUNT_RESET]() {
        RestService.put('/cfd/account/'+state.demoAccount+'/reset')
            .then(() => {
                this.commit(NOTIFICATION, 'Demo account has been reset to initial balance')
            })
    }
}

const mutations = {
    [USER]() {
        RestService.get('/accounts')
            .then(data => {
                let activeAccount = parseInt(localStorage.getItem('activeAccount') || '0'),
                    cfdAccount = null,
                    accountActivated = false,
                    query = []

                    for(let i in data.accounts){
                        query += `variables[]=CFD_ACCOUNT_NAME_${data.accounts[i].group.toUpperCase()}&`
                    }

                    RestService.get(`/variables?${query}`).then(res => {
                        for (let i in data.accounts) {

                            let variableName = `CFD_ACCOUNT_NAME_${data.accounts[i].group.toUpperCase()}`;

                            if (res[variableName]) {
                                data.accounts[i].account_name = res[variableName].toUpperCase();
                            }
                        }
                    })

                for(let i in data.accounts) {
                    let acc = data.accounts[i]

                    this.commit(ACCOUNT, acc)

                    if (acc.product === 'cfd') {
                        cfdAccount = acc.id

                        if (acc.id === activeAccount) {
                            this.commit(ACCOUNT_ACTIVE, acc.id)

                            accountActivated = true
                        }
                    }
                }

                if (!accountActivated) {
                    if (cfdAccount) {
                        this.commit(ACCOUNT_ACTIVE, cfdAccount)
                    } else {
                        this.commit(NOTIFICATION_ERROR, 'LOCAL_NO_TRADING_ACCOUNTS')
                    }
                }
            })
    },

    [DATA_REFRESH]() {
        RestService.get('/accounts')
            .then(data => {
                for(let i in data.accounts) {
                    let acc = data.accounts[i]

                    this.commit(ACCOUNT, acc)
                }
            })
    },

    [ACCOUNT](context, account) {
        if (account.product !== 'cfd') {
            return
        }

        if (state.accounts[account.id] !== undefined) {
            account.orders = state.accounts[account.id].orders
            account.history = state.accounts[account.id].history
            Vue.set(state.accounts, account.id, account)
            return
        }

        if (account.demo) {
            state.demoAccount = account.id
        } else {
            state.realAccount = account.id
        }

        account.orders = {}
        account.history = []
        account.currencySign = currencySign(account.currency)

        Vue.set(state.accounts, account.id, account)
    },

    [ACCOUNT_ACTIVE](context, account) {
        state.activeAccount = account

        localStorage.setItem('activeAccount', state.activeAccount)
        RestService.get('/cfd/'+account+'/symbols')
            .then(symbols => {
                let oldList = []

                for(let n in this.getters.symbols) {
                    oldList.push(this.getters.symbols[n].symbol)
                }

                for(let i in symbols) {
                    let index = oldList.indexOf(symbols[i].symbol)

                    if (index !== -1) {
                        oldList.splice(index, 1)
                    }

                    this.commit(SYMBOL, symbols[i])
                }

                for(let i in oldList) {
                    this.commit(SYMBOL_REMOVE, oldList[i])
                }
            })

        this.commit(SORT_ORDERS, {
            account: account,
            sort: 'time_open'
        })
        this.commit(SORT_HISTORY_ORDERS, {
            account: account,
            sort: 'time_close'
        })

        if (localStorage.activeCurrency === undefined || localStorage.activeCurrency !== state.accounts[state.activeAccount]){
            localStorage.activeCurrency = state.accounts[state.activeAccount].currency
        }
    },

    [CFD_HISTORY](context, history) {
        if (!history.rows || !history.rows.length) {
            return
        }

        let ords = [],
            accountId = history.rows[0].account_id

        if (!state.accounts[accountId]) {
            return
        }

        if (!state.accounts[accountId]) {
            return
        }

        for(let n in history.rows) {
            ords.push(history.rows[n])
        }

        Vue.set(state.accounts[accountId], 'history', ords)
        Vue.set(state.accounts[accountId], 'profitTotal', history.profit_total)
    },

    [CFD_ADD_PROFIT_TOTAL](context, delta) {
        isNaN(state.accounts[state.activeAccount].profitTotal) ? state.accounts[state.activeAccount].profitTotal = delta : state.accounts[state.activeAccount].profitTotal += delta
    },

    [CFD_ORDER](context, order) {
        if (state.accounts[order.account_id] === undefined) {
            return
        }

        let orderKeys,
            orderIndex

        if (order.time_close > 0 && state.accounts[order.account_id].orders) {
            orderKeys = Object.keys(state.accounts[order.account_id].orders);
            orderIndex = orderKeys.find(orderKey => state.accounts[order.account_id].orders[orderKey].id === order.id);

            Vue.delete(state.accounts[order.account_id].orders, orderIndex)
            state.accounts[order.account_id].history.unshift(order)

            this.commit(CFD_ADD_PROFIT_TOTAL, order.profit)
            let orderForNotification = Object.assign({}, order)
            orderForNotification.profit = order.profit
            if (order.profit > 0) {
                this.dispatch(PLAY_ORDER_WIN)
            } else {
                this.dispatch(PLAY_ORDER_LOSE)
            }

            this.commit(NOTIFICATION_ORDER, orderForNotification)
        } else {
            if (state.accounts[order.account_id].orders === undefined) {
                Vue.set(state.accounts[order.account_id], 'orders', {
                    [order.id]: order
                })

                this.commit(NOTIFICATION_ORDER_OPEN, order)
            } else {
                if (state.accounts[order.account_id].orders[order.id] === undefined && !order.isUpdate) {
                    this.commit(NOTIFICATION_ORDER_OPEN, order)
                }

                orderKeys = Object.keys(state.accounts[order.account_id].orders);
                orderIndex = orderKeys.find(orderKey => state.accounts[order.account_id].orders[orderKey].id === order.id);

                orderIndex ? Vue.set(state.accounts[order.account_id].orders, orderIndex, order) : Vue.set(state.accounts[order.account_id].orders, orderKeys.length, order)
            }

            if (!order._silent && !order.isUpdate) {
                this.dispatch(PLAY_ORDER_OPEN)
            }
        }
    },

    [CFD_ORDERS](context, orders) {
        if (!orders || !orders.length) {
            return
        }

        const accountId = orders[0].account_id

        if (!state.accounts[accountId]) {
            return
        }

        Vue.set(state.accounts[accountId], 'orders', {})

        for (const orderId in orders) {
            Vue.set(state.accounts[accountId].orders, orderId, orders[orderId]);
        }
    },

    [BALANCE_UPDATE](context, balance) {
        if (balance.product === 'cfd' && state.accounts[balance.account_id] !== undefined) {
            state.accounts[balance.account_id].balance += balance.balanceChange
        }
    },

    [CFD_TRADE_DISABLED](context, val) {
        state.tradeDisabled = val
    },

    [LOGOUT]() {
        Vue.set(state, 'accounts',  {})
        localStorage.removeItem('activeAccount')
        localStorage.removeItem('activeCurrency')
        state.activeAccount = 0
        state.demoAccount = 0
        state.realAccount = 0
    },

    [SET_ACCOUNT](context, account) {
        for (let n in state.accounts) {
            if(n == account.id) {
                Vue.set(state.accounts[n], 'enable', account.enable === "0" ? false : true)
            }
        }
    },

    [SORT_ORDERS](context, data) {
        RestService.get('/cfd/'+data.account+'/orders', {
            order: [data.sort, data.sort === state.sortBy && state.currentSort === 'DESC' ? 'ASC' : 'DESC']
        })
            .then(orders => {
                if (orders.rows) {
                    state.sortBy = data.sort
                    data.sort === state.sortBy && state.currentSort === 'DESC' ? state.currentSort = 'ASC' : state.currentSort = 'DESC'
                    this.commit(CFD_ORDERS, orders.rows)
                }
            })
    },

    [SORT_HISTORY_ORDERS](context, data) {
        RestService.get('/cfd/'+data.account+'/orders_history', {
            order: [data.sort, data.sort === state.sortBy && state.currentSort === 'DESC' ? 'ASC' : 'DESC']
        })
            .then(orders => {
                state.sortBy = data.sort
                data.sort === state.sortBy && state.currentSort === 'DESC' ? state.currentSort = 'ASC' : state.currentSort = 'DESC'
                this.commit(CFD_HISTORY, orders)
            })

    },

    [SET_LIGHT_THEME](context, lightTheme){
        state.lightTheme = lightTheme
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
